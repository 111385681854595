import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useFetchRequest, { url } from "../Hooks/FetchRequest";

import { addToBasket } from "../Components/Basket";
import GoBack from "../Components/GoBack";
import CrossImage from "../assets/img/plus.svg";

function ProductDetail() {
    const { id } = useParams();
    const product = useFetchRequest("GET", false, `/shop/products/${id}`);
    const [mainUrl, setMainUrl] = useState();
    const [enlarged, setEnlarged] = useState(false);
    const { i18n } = useTranslation(["shop", "common"]);
    let content = <h2>{"common:loading"}</h2>;

    useEffect(() => {
        window.scrollTo(0, 0);

        if (product) {
            setMainUrl(product.images[0].image);
        }
    }, [product]);

    if (product && mainUrl) {
        content = (
            <Fragment>
                {enlarged && (
                    <EnlargedImage
                        mainUrl={mainUrl}
                        title={
                            product.titleproduct.titles.filter(
                                (title) => title.lang === i18n.languages[0]
                            )[0].title
                        }
                        setEnlarged={setEnlarged}
                    />
                )}
                <section className="productDetail">
                    <GoBack />
                    <div>
                        <img
                            src={url + mainUrl}
                            alt={
                                product.titles.filter(
                                    (title) => title.lang === i18n.languages[0]
                                )[0].title
                            }
                            onClick={() => setEnlarged(true)}
                        />
                        <div>
                            {product.images.length > 1 &&
                                product.images.map((image, key) => {
                                    return (
                                        <img
                                            key={key}
                                            onClick={() =>
                                                setMainUrl(image.image)
                                            }
                                            src={url + image.image}
                                            alt={
                                                product.titles.filter(
                                                    (title) =>
                                                        title.lang ===
                                                        i18n.languages[0]
                                                )[0].title
                                            }
                                        />
                                    );
                                })}
                        </div>
                    </div>
                    <div>
                        <h2>
                            {
                                product.titles.filter(
                                    (title) => title.lang === i18n.languages[0]
                                )[0].title
                            }
                        </h2>
                        {product.descriptions
                            .filter((desc) => desc.lang === i18n.languages[0])
                            .map((description, key) => {
                                return (
                                    <p key={key}>{description.description}</p>
                                );
                            })}

                        <div>
                            <h3>
                                <sup>€</sup>
                                {product.discount && product.discount !== 0
                                    ? (
                                          product.price *
                                          (1 - product.discount / 100)
                                      ).toFixed(2)
                                    : product.price.toFixed(2)}
                            </h3>
                            <button
                                onClick={(e) =>
                                    processAddToBasket(e, product.id)
                                }
                            >
                                order
                            </button>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }

    function processAddToBasket(e, id) {
        addToBasket(id);
        togglePopup(e.target);
    }

    function togglePopup(button) {
        button.classList.add("popup-box");
        setTimeout(() => {
            button.classList.remove("popup-box");
        }, 1000);
    }

    return content;
}

function EnlargedImage({ mainUrl, title, setEnlarged }) {
    return (
        <span className="enlarged-image">
            <div>
                <img
                    src={CrossImage}
                    alt="close"
                    onClick={() => setEnlarged(false)}
                />
                <img src={url + mainUrl} alt={title} />
            </div>
        </span>
    );
}

export default ProductDetail;
