import React from "react";
import { animated, useSpring } from "react-spring";

function Popup({ visible, title, description, setPopup }) {
    const animation = useSpring({ opacity: visible ? 1 : 0 });

    return (
        visible && (
            <animated.div style={animation} className="popup">
                <span></span>
                <div>
                    <h1>{title}</h1>
                    <p>{description}</p>
                    <button onClick={(e) => handleClick(e)}>OK</button>
                </div>
            </animated.div>
        )
    );

    function handleClick(e) {
        e.preventDefault();
        setPopup({ description: "", status: false });
    }
}

export default Popup;
