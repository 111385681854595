function formDataToJSON(formData) {
    let object = {
        description: [],
        title: [],
    };
    formData.forEach((value, key) => {
        if (key.includes("description")) {
            let lang = key.split("-")[1];

            let langObject = {};
            langObject[lang] = value;
            object.description.push(langObject);
        } else if (key.includes("title")) {
            let lang = key.split("-")[1];

            let langObject = {};
            langObject[lang] = value;
            object.title.push(langObject);
        } else {
            object[key] = value;
        }
    });
    console.log(object);
    return object;
}

export default formDataToJSON;
