import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import BelgianFlag from "../assets/img/flags/belgian_flag.png";
import FrenchFlag from "../assets/img/flags/french_flag.png";
import GermanFlag from "../assets/img/flags/german_flag.png";
import BrittishFlag from "../assets/img/flags/brittish_flag.png";

const LanguageSelector = () => {
    const languages = {
        nl: BelgianFlag,
        fr: FrenchFlag,
        de: GermanFlag,
        en: BrittishFlag,
    };

    const { i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedFlag, setSelectedFlag] = useState("en");

    useEffect(() => {
        i18n.changeLanguage(selectedFlag);
    }, [selectedFlag, i18n]);

    return (
        <div className="flag-container" onClick={() => setOpen(!open)}>
            {!open && (
                <img
                    src={languages[selectedFlag]}
                    alt="Vertalen naar Nederlands"
                />
            )}
            <div className={`options ${open ? "flex" : "none"}`}>
                <img
                    src={BelgianFlag}
                    alt="Vertalen naar Nederlands"
                    onClick={() => setSelectedFlag("nl")}
                />
                <img
                    src={FrenchFlag}
                    alt="traduire en français"
                    onClick={() => setSelectedFlag("fr")}
                />
                <img
                    src={GermanFlag}
                    alt="Übersetzen in Deutsch"
                    onClick={() => setSelectedFlag("de")}
                />
                <img
                    src={BrittishFlag}
                    alt="Translate to English"
                    onClick={() => setSelectedFlag("en")}
                />
            </div>
        </div>
    );
};

export default LanguageSelector;
