import React, { Fragment, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";

import { url } from '../Hooks/FetchRequest';

import BannerImage from "../assets/img/dayachocolates-banner.jpg";


function Login(){
    const [error, setError] = useState(false);
    const history = useHistory();

    if (localStorage.getItem("Token")) { return <Redirect to="/dashboard" />}

    return (
        <Fragment>
            <img src={BannerImage} alt="banner" className="login-banner"/>
            <section className="login">

                <form onSubmit={(e) => requestToken(e)}>
                    <h2>Log hier in</h2>

                    {error && <p>Username of login incorrect probeer opnieuw</p>}
                    <label htmlFor="username">Username</label>
                    <input id="username" required name="username" autoFocus />

                    <label htmlFor="password">Password</label>
                    <input id="password" type="password" required name="password" />

                    <input type="submit" value="Log In"/>
                </form>
            </section>
        </Fragment>
    );

    function requestToken(e){
        e.preventDefault();

        let data = new FormData(e.target);
    
        let options = {
            method: "POST",
            body: data,
        };
    
    
        fetch(`${url}/shop/login`, options)
            .then(async (r) => {
                if (r.ok){
                    let token = await r.json();

                    localStorage.setItem("Token", `Token ${token.token}`)
                    history.push("/dashboard");
                }
                else {
                    setError(true);
                }
            })
    }
}


export default Login;