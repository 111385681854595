import { useState, useEffect } from "react";

export let url =
    process.env.NODE_ENV === "production"
        ? `https://dayachocolates.com/api`
        : `http://127.0.0.1:8000/api`;

function useFetchRequest(
    method = "GET",
    authorization = false,
    apiPoint,
    body = null
) {
    const [data, setdata] = useState(null);

    useEffect(() => {
        let options = {
            method,
        };

        if (authorization) {
            options["headers"] = {
                Authorization: `${localStorage.getItem("Token")}`,
            };
        }

        if (body) {
            options["body"] = JSON.stringify(body);
        }

        fetch(`${url}${apiPoint}`, options)
            .then((r) => r.json())
            .then((response_data) => {
                setdata(response_data);
            });
    }, [method, authorization, apiPoint, body]);

    return data;
}

export default useFetchRequest;
