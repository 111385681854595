export function calculatePrice(product) {
    return (product.price * (1 - product.discount / 100)).toFixed(2);
}

export function calculatePriceExclVat(products) {
    let price = 0.0;
    products.forEach((product) => {
        price +=
            product.price * (1 - product.discount / 100) * product.quantity;
    });
    return price.toFixed(2);
}

export function calculateVAT(products) {
    let VAT = 0.0;
    products.forEach((product) => {
        VAT +=
            product.price *
            (1 - product.discount / 100) *
            (product.vat / 100) *
            product.quantity;
    });

    return VAT.toFixed(2);
}

export function calculateTotal(products, shipmentCost) {
    return (
        parseFloat(calculatePriceExclVat(products)) + parseFloat(shipmentCost)
    );
}
