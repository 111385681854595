import React from "react";
import { useTranslation } from "react-i18next";

import BannerImage from "../assets/img/dayachocolates-banner.jpg";

function TOC() {
    const { t } = useTranslation(["tos"]);

    return (
        <div className="TOC">
            <img src={BannerImage} alt="banner" />
            <div>
                <h1>{t("title")}</h1>
                <h2>{t("terms of sale")}</h2>
                <p>{t("article one")}</p>
                <p>{t("article two")}</p>
            </div>
        </div>
    );
}

export default TOC;
