import React, { useState, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";

import formDataToJSON from "../Functions/FormDataToJSON";

import { url } from "../Hooks/FetchRequest";

import LoadingOverlay from "../Components/LoadingOverlay";

function AddProduct() {
    const [images, setImages] = useState([]);
    const [Loading, setLoading] = useState(false);
    const history = useHistory();
    const imageSelector = useRef(null);

    if (!localStorage.getItem("Token")) {
        return <Redirect to="/login" />;
    }

    let content = (
        <div className="add-product">
            <LoadingOverlay visible={Loading} />
            <h2>Voeg hier een nieuw product toe</h2>
            <form onSubmit={(e) => submitForm(e)}>
                <label htmlFor="title-en">Artikelnaam</label>

                <p>Engels</p>
                <input name="title-en" id="title-en" required autoFocus />

                <p>Nederlands</p>
                <input name="title-nl" id="title-nl" required />

                <p>Duits</p>
                <input name="title-de" id="title-de" required />

                <p>Frans</p>
                <input name="title-fr" id="title-fr" required />

                <label htmlFor="price">Prijs</label>
                <input
                    type="number"
                    name="price"
                    step="0.01"
                    min="0.01"
                    required
                />

                <label htmlFor="vat">BTW</label>
                <input
                    id="vat"
                    name="vat"
                    type="number"
                    step="1"
                    min="0"
                    required
                    className="info"
                />
                <h6>BTW percentage</h6>

                <label htmlFor="discount">Korting</label>
                <input
                    id="discount"
                    name="discount"
                    type="number"
                    step="1"
                    className="info"
                />
                <h6>Korting in procent. Optioneel.</h6>

                <label htmlFor="measurement">Maateenheid</label>
                <input id="measurement" name="measurement" className="info" />
                <h6>Maateenheid van het product bv.: reep of kg. Optioneel.</h6>

                <label htmlFor="weight">Gewicht</label>
                <input
                    id="weight"
                    name="weight"
                    type="number"
                    step="0.01"
                    className="info"
                />
                <h6>Gewicht in kg.</h6>

                <label htmlFor="description-en">Beschrijving</label>
                <p>Engels</p>
                <textarea
                    name="description-en"
                    id="description-en"
                    required
                ></textarea>

                <p>Nederlands</p>
                <textarea
                    name="description-nl"
                    id="description-nl"
                    required
                ></textarea>

                <p>Duits</p>
                <textarea
                    name="description-de"
                    id="description-de"
                    required
                ></textarea>

                <p>Frans</p>
                <textarea
                    name="description-fr"
                    id="description-fr"
                    required
                ></textarea>

                <label htmlFor="category">Categorie</label>
                <select id="category" name="category">
                    <option value="">Geen</option>
                    <option value="chocolate tablets">chocolate tablets</option>
                    <option value="Pralines">Pralines</option>
                    <option value="chocolate">chocolate</option>
                    <option value="handmade">handmade</option>
                    <option value="Truffels">Truffels</option>
                    <option value="promotions">promotions</option>
                    <option value="figures">figures</option>
                    <option value="Souvenir">Souvenir</option>
                    <option value="candy">candy</option>
                    <option value="packing">packing</option>
                </select>

                <label htmlFor="images">Afbeeldingen</label>
                <input
                    id="images"
                    name="images"
                    ref={imageSelector}
                    onChange={(e) => addImage(e)}
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    multiple
                    required
                />
                <div className="flex-row image-row">
                    {images.map((image, key) => {
                        return (
                            <img
                                key={key}
                                src={URL.createObjectURL(image)}
                                alt={image.name}
                            />
                        );
                    })}
                </div>
                <button
                    formNoValidate
                    onClick={(e) => {
                        e.preventDefault();
                        imageSelector.current.click();
                    }}
                    htmlFor="image"
                >
                    Voeg afbeelding toe
                </button>

                <input type="submit" value="Voeg product toe" />
            </form>
        </div>
    );

    function addImage(e) {
        setImages([...e.target.files]);
    }

    function submitForm(e) {
        e.preventDefault();
        setLoading(true);
        let body = formDataToJSON(new FormData(e.target));

        if (body.discount === "") {
            body.discount = 0;
        }

        const options = {
            method: "POST",
            headers: {
                Authorization: `${localStorage.getItem("Token")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };

        fetch(`${url}/shop/products`, options).then(async (r) => {
            if (r.ok) {
                const { id } = await r.json();

                for (let i = 0; i < imageSelector.current.files.length; i++) {
                    let imageBody = new FormData();
                    imageBody.append("image", imageSelector.current.files[i]);

                    let image_options = {
                        method: "POST",
                        headers: {
                            authorization: `${localStorage.getItem("Token")}`,
                        },
                        body: imageBody,
                    };

                    await fetch(
                        `${url}/shop/products/${id}/image`,
                        image_options
                    );
                }

                history.push("/dashboard");
            }
        });
    }

    return content;
}

export default AddProduct;
