import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";

import useFetchRequest from "../Hooks/FetchRequest";
import useInfiniteScroll from "../Hooks/useInfiniteScroll";

import Product from "../Components/Product";
import FilterMenu from "../Components/FilterMenu";

import BannerImage from "../assets/img/dayachocolates-banner.jpg";

function Shop() {
    const [category, setCategory] = useState(null);
    const products = useFetchRequest(
        "GET",
        false,
        category ? `/shop/products?category=${category}` : `/shop/products`
    );
    const [allProducts, setProducts] = useState([]);
    const [nextList, setNextList] = useState();
    // eslint-disable-next-line
    const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
    const [FilterMenuVisible, setFilterMenuVisible] = useState(false);
    const filterMenuAnimation = useSpring({
        opacity: FilterMenuVisible ? 1 : 0,
        display: FilterMenuVisible ? "flex" : "none",
    });
    let content = <h2>Please wait</h2>;

    useEffect(() => {
        if (!products) return;
        setNextList(products.next);
        setProducts(products.results);
        window.scrollTo(0, 0);
    }, [products]);

    if (products) {
        content = (
            <div className="shop-container">
                <img src={BannerImage} alt="banner" />
                <button
                    className="filter-icon"
                    onClick={(e) => setFilterMenuVisible(!FilterMenuVisible)}
                >
                    Filter
                </button>
                <animated.div
                    onClick={() => setFilterMenuVisible(false)}
                    style={filterMenuAnimation}
                    className="filtermenu"
                >
                    <FilterMenu
                        setFilterMenuVisible={setFilterMenuVisible}
                        setCategory={setCategory}
                    />
                </animated.div>
                <section className="shop">
                    {allProducts.map((product, index) => (
                        <Product
                            key={index}
                            product={product}
                            link={`/shop/${product.id}`}
                        />
                    ))}
                </section>
            </div>
        );
    }

    function fetchMoreListItems() {
        if (!nextList) return;
        fetch(nextList).then(async (r) => {
            if (!r.ok) return;
            const result = await r.json();
            setProducts([...allProducts, ...result.results]);
            setNextList(result.next);
            setIsFetching(false);
        });
    }

    return content;
}

export default Shop;
