import React, { Fragment, useState, useEffect } from "react";
import useFetchRequest, { url } from "../Hooks/FetchRequest";
import { useTranslation } from "react-i18next";

import Product from "../Components/Product";

import LocationIcon from "../assets/img/location-icon.svg";
import ArrowLeft from "../assets/img/Arrow-left.png";
import ArrowRight from "../assets/img/Arrow-right.png";
import AboutUsImage from "../assets/img/store-banner.jpg";
import StoreImage from "../assets/img/interior.jpg";
import YearsExperienceImage from "../assets/img/10-years.png";
import CupcakesImage from "../assets/img/chocolat_bar.png";
import StarImage from "../assets/img/star.png";

function Home() {
    const Text = useFetchRequest("GET", false, "/home/content");
    const ReviewsContent = useFetchRequest("GET", false, "/home/reviews");
    const shopItems = useFetchRequest("GET", false, "/shop/products?limit=3");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let content = "";

    if (Text && ReviewsContent && shopItems) {
        content = (
            <Fragment>
                <main>
                    <Header />
                    <AboutUs text={Text} />
                    <YearsExperience text={Text} />
                    <Discover text={Text} />
                    <ShopPreview shopItems={shopItems} />
                    <Reviews reviewMessages={ReviewsContent} />
                </main>
            </Fragment>
        );
    }

    return content;
}

function Header() {
    const { t } = useTranslation(["home", "common"]);
    const imageArray = useFetchRequest("GET", false, "/home/carousel");
    const [index, setIndex] = useState(0);
    let content = <h2>{t("common:loading")}</h2>;

    if (imageArray) {
        content = (
            <header>
                <section className="banner">
                    <h2>
                        {t("heading")}
                        <br />
                        <em>{t("emphasisHeading")}</em>
                    </h2>
                    <div>
                        <p>
                            <img src={LocationIcon} alt="location" />{" "}
                            {t("common:locationHeading")}
                        </p>
                        <h3>{t("common:city")}</h3>
                        <h4>{t("common:street")}</h4>

                        <p>{t("subTitle")}</p>
                    </div>
                </section>
                <div className="carousel">
                    <img src={url + imageArray[index].image} alt="banner" />
                    <div>
                        <img
                            src={ArrowLeft}
                            alt="Previous"
                            onClick={() => changeIndex(-1)}
                        />
                        <img
                            src={ArrowRight}
                            alt="Next"
                            onClick={() => changeIndex(1)}
                        />
                    </div>
                </div>
            </header>
        );
    }

    function changeIndex(amount) {
        let i = index;
        i += amount;
        if (i < 0) {
            i = 2;
        } else if (i > 2) {
            i = 0;
        }
        setIndex(i);
    }

    return content;
}

function AboutUs({ text }) {
    const { t, i18n } = useTranslation(["home", "common"]);

    return (
        <section className="about-us" id="aboutus">
            <div>
                <img src={AboutUsImage} alt="Store owner" />
                <div>
                    <h2>{t("aboutUs")}</h2>
                    {text
                        .filter((about) => about.lang === i18n.languages[0])[0]
                        ["aboutUs"].split("\n")
                        .map((paragraph, index) => {
                            return <p key={index}>{paragraph}</p>;
                        })}
                </div>
                <div>
                    <h2>{t("ourShop")}</h2>
                    {text
                        .filter((about) => about.lang === i18n.languages[0])[0]
                        ["ourShop"].split("\n")
                        .map((paragraph, index) => {
                            return <p key={index}>{paragraph}</p>;
                        })}
                </div>
                <img src={StoreImage} alt="Our store" />
            </div>
        </section>
    );
}

function YearsExperience({ text }) {
    const { t, i18n } = useTranslation(["home", "common"]);

    return (
        <section className="years-experience">
            <img src={YearsExperienceImage} alt="10+ years experience" />
            <div>
                <h3>{t("10Years")}</h3>
                <h4>{t("yearsExperience")}</h4>
                {text
                    .filter((text) => text.lang === i18n.languages[0])[0]
                    ["yearsExperience"].split("\n")
                    .map((paragraph, index) => {
                        return <p key={index}>{paragraph}</p>;
                    })}
            </div>
        </section>
    );
}

function Discover({ text }) {
    const { t, i18n } = useTranslation(["home", "common"]);

    return (
        <section className="discover">
            <div>
                <h3>{t("discover")}</h3>
                <h4>{t("tastes")}</h4>
                {text
                    .filter((text) => text.lang === i18n.languages[0])[0]
                    ["newTastes"].split("\n")
                    .map((paragraph, index) => {
                        return <p key={index}>{paragraph}</p>;
                    })}
            </div>
            <img src={CupcakesImage} alt="cupcakes" />
        </section>
    );
}

function ShopPreview(props) {
    let { shopItems } = props;
    const { t } = useTranslation(["home", "common"]);

    return (
        <section className="shop-preview">
            <h2>{t("explore")}</h2>
            <div>
                {shopItems.results.map((product, index) => (
                    <Product key={index} product={product} link={`/shop`} />
                ))}
            </div>
        </section>
    );
}

function Reviews(props) {
    const { reviewMessages } = props;
    const { t, i18n } = useTranslation(["home", "common"]);

    return (
        <section className="reviews">
            <h2>{t("reviews")}</h2>
            <div>
                <img src={StarImage} alt="Star" />
                <img src={StarImage} alt="Star" />
                <img src={StarImage} alt="Star" />
                <img src={StarImage} alt="Star" />
                <img src={StarImage} alt="Half star" />
                <p>{t("stars")}</p>
            </div>
            <p>{t("reviewCount")}</p>

            {reviewMessages
                .filter((message) => message.lang === i18n.languages[0])
                .map((message, index) => {
                    return (
                        <p
                            key={index}
                            className={
                                index % 2 === 0
                                    ? "left-direction"
                                    : "right-direction"
                            }
                        >
                            {message.content}
                        </p>
                    );
                })}
        </section>
    );
}

export default Home;
