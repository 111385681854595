import React from "react";
import { useHistory } from "react-router-dom";

import BackArrow from "../assets/img/back.svg";

function GoBack(){
    const history = useHistory();

    let content = (
        <div onClick={() => history.goBack()} className="backButton">
            <img src={BackArrow} alt="Go back" />
        </div>
    )

    return content;
}

export default GoBack;