import React from "react";

function FilterMenu({setCategory, setFilterMenuVisible}) {
    const categories = [
        "All",
        "chocolate tablets",
        "Pralines",
        "chocolate",
        "handmade",
        "Truffels",
        "promotions",
        "figures",
        "Souvenir",
        "candy",
        "packing"
    ]
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <h1>Filter categories</h1>
            <select onChange={(e) => handleClick(e.target.value)}>
                {categories.map((cat, key) => {
                    return <option key={key} value={cat === "All" ? "" : cat}>{cat}</option>;
                })}
            </select>
            <ul>
            </ul>
        </div>
    )

    function handleClick(cat){
        setCategory(cat);
        setFilterMenuVisible(false);
    }
}

export default FilterMenu;