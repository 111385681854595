import React, { useEffect, useState } from "react";

import ShoppingCart from "../assets/img/shopping-cart.svg";

function Basket() {
    const [basketCount, setBasketCount] = useState(0);

    useEffect(() => {
        const shoppingBag = JSON.parse(localStorage.getItem("basket"));
        setBasketCount(shoppingBag?.length);
    }, []);

    return (
        <div className="shopping-cart-container">
            <img
                className="shoppingcart"
                src={ShoppingCart}
                alt="Shopping cart"
            />

            <ShoppingcartAmount basketCount={basketCount} />
        </div>
    );
}

export function addToBasket(id) {
    let shoppingBag = JSON.parse(localStorage.getItem("basket")) || [];
    let foundItem = shoppingBag.findIndex((item) => item.id === id);

    if (foundItem !== -1) {
        shoppingBag[foundItem] = {
            id: shoppingBag[foundItem].id,
            quantity: parseInt(shoppingBag[foundItem].quantity) + 1,
        };
    } else {
        shoppingBag.push({ id, quantity: 1 });
    }

    localStorage.setItem("basket", JSON.stringify(shoppingBag));

    updateBasketAmount(shoppingBag.length);

    function updateBasketAmount(amount) {
        if (
            document.querySelector(
                ".shopping-cart-container .shopping-bag-count"
            )
        ) {
            document.querySelector(".shopping-bag-count p").innerHTML = amount;
        } else {
            document.querySelector(
                ".shopping-cart-container"
            ).innerHTML += `<div class="shopping-bag-count">
                                                                                <p>${amount}</p>
                                                                            </div>`;
        }
    }
}

function ShoppingcartAmount(props) {
    const { basketCount } = props;
    if (basketCount) {
        return (
            <div className="shopping-bag-count">
                <p>{basketCount}</p>
            </div>
        );
    } else {
        return true;
    }
}

export function deleteFromBasket(index) {
    let basket = JSON.parse(localStorage.getItem("basket"));
    localStorage.setItem(
        "basket",
        JSON.stringify(basket.filter((item, i) => i !== index))
    );
}

export function deleteBasket() {
    let basket = [];
    localStorage.setItem("basket", JSON.stringify(basket));
}

export default Basket;
