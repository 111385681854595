import React, { useEffect, useState, useRef } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Transition } from "react-spring/renderprops";

import useFetchRequest, { url } from "../Hooks/FetchRequest";

import CheckImage from "../assets/img/check.svg";
import PlusIcon from "../assets/img/plus.svg";
import EditImage from "../assets/img/edit.svg";

function Dashboard() {
    if (!localStorage.getItem("Token")) {
        return <Redirect to="/login" />;
    }

    return (
        <section className="dashboard">
            <UserInformation />

            <div className="flex-container">
                <OrderView />

                <ProductView />
            </div>
        </section>
    );
}

function UserInformation() {
    const info = useFetchRequest("GET", true, "/shop/user");
    let content = (
        <div className="personal-info">
            <h2>Loading...</h2>
        </div>
    );

    if (info) {
        content = (
            <div className="personal-info">
                <h2>
                    {info.first_name} {info.family_name}
                </h2>
                <h3>Daya Chocolates</h3>
            </div>
        );
    }

    return content;
}

function OrderView() {
    const fetchedOrders = useFetchRequest("GET", true, "/shop/orders");
    const [orders, setOrders] = useState();
    let content = (
        <div className="orders">
            <h2>Loading...</h2>
        </div>
    );

    useEffect(() => {
        setOrders(fetchedOrders);
    }, [fetchedOrders]);

    if (orders) {
        if (orders.length !== 0) {
            content = (
                <div className="orders">
                    <h2>Orders</h2>
                    <ul>
                        <Transition
                            items={orders}
                            keys={(item) => item.id}
                            from={{ transform: "translateX(25px)" }}
                            enter={{ transform: "translateX(0px)", opacity: 1 }}
                            leave={{
                                transform: "translateX(-75px)",
                                opacity: 0,
                            }}
                        >
                            {(order) => (props) =>
                                (
                                    <li style={props}>
                                        <p>
                                            {`${order.customer.first_name} ${
                                                order.customer.family_name
                                            }: ${new Date(
                                                order.date_time
                                            ).toUTCString()}`}
                                            <br />
                                            {`${order.customer.address}, ${order.customer.postal_code} ${order.customer.city} ${order.customer.country}`}
                                        </p>{" "}
                                        <img
                                            src={CheckImage}
                                            alt="Verzonden"
                                            onClick={() =>
                                                completeOrder(order.id)
                                            }
                                        />
                                    </li>
                                )}
                        </Transition>
                    </ul>
                </div>
            );
        } else {
            content = (
                <div className="orders">
                    <h2>Orders - Geen openstaande orders</h2>
                </div>
            );
        }
    }

    function completeOrder(id) {
        const options = {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${localStorage.getItem("Token")}`,
            },
            body: JSON.stringify({ status: true }),
        };

        fetch(`${url}/shop/order/${id}`, options).then((r) => {
            if (r.ok) {
                setOrders(orders.filter((order) => order.id !== id));
            }
        });
    }

    return content;
}

function ProductView() {
    const fetched_products = useFetchRequest("GET", true, "/shop/products");
    const [nextList, setNextList] = useState();
    const [products, setProducts] = useState();
    const history = useHistory();
    const form = useRef(null);
    let content = (
        <div className="products">
            <h2>Loading...</h2>
        </div>
    );

    useEffect(() => {
        if (fetched_products) {
            setProducts(fetched_products.results);
            setNextList(fetched_products.next);
        }
    }, [fetched_products]);

    if (products) {
        content = (
            <div className="products">
                <h2>Producten</h2>
                <form onSubmit={handleSubmit} ref={form}>
                    <label htmlFor="searchQuery">
                        Zoek een product op naam of prijs
                    </label>
                    <div>
                        <input
                            name="searchQuery"
                            id="searchQuery"
                            onChange={handleSubmit}
                        />
                        <input type="submit" value="Zoek" />
                    </div>
                </form>
                <ul>
                    <Transition
                        items={products}
                        keys={(item) => item.id}
                        from={{ transform: "translateX(25px)" }}
                        enter={{ transform: "translateX(0px)", opacity: 1 }}
                        leave={{ transform: "translateX(-75px)", opacity: 0 }}
                    >
                        {(product) => (props) =>
                            (
                                <li
                                    style={props}
                                    className={
                                        product.inStock ? "" : "out-of-stock"
                                    }
                                >
                                    <p>{`${
                                        product.titles[0].title
                                    } €${product.price.toFixed(2)}`}</p>
                                    <p>{`${
                                        product.discount !== 0
                                            ? product.discount + "%"
                                            : "Geen korting"
                                    }`}</p>{" "}
                                    <img
                                        src={EditImage}
                                        alt="edit"
                                        onClick={() =>
                                            history.push(
                                                `/updateproduct/${product.id}`
                                            )
                                        }
                                    />
                                    <img
                                        src={PlusIcon}
                                        alt="delete"
                                        onClick={() =>
                                            deleteProduct(product.id)
                                        }
                                    />
                                </li>
                            )}
                    </Transition>

                    {nextList && (
                        <li className="load-more">
                            <button onClick={() => loadMoreProducts()}>
                                Meer producten laden
                            </button>
                        </li>
                    )}
                    <li>
                        <img
                            src={PlusIcon}
                            alt="Voeg item toe"
                            onClick={() => history.push("/addproduct")}
                        />
                    </li>
                </ul>
            </div>
        );
    }

    function deleteProduct(id) {
        const options = {
            method: "DELETE",
            headers: {
                Authorization: `${localStorage.getItem("Token")}`,
            },
        };

        fetch(`${url}/shop/products/${id}`, options).then((r) => {
            if (r.ok) {
                setProducts(products.filter((prod) => prod.id !== id));
            }
        });

        return null;
    }

    function loadMoreProducts() {
        const options = {
            method: "GET",
            headers: {
                Authorization: `${localStorage.getItem("Token")}`,
            },
        };

        fetch(nextList, options).then(async (r) => {
            if (r.ok) {
                let newProducts = await r.json();
                setProducts([...products, ...newProducts.results]);
                setNextList(newProducts.next);
            }
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        const searchTerms = new FormData(form.current);
        const searchQuery = searchTerms.get("searchQuery");

        if (e.type === "change" && searchQuery !== "") return;

        const options = {
            method: "GET",
            headers: {
                Authorization: `${localStorage.getItem("Token")}`,
            },
        };

        fetch(
            searchQuery === ""
                ? `${url}/shop/products`
                : `${url}/shop/products/filter?query=${searchQuery}`,
            options
        ).then(async (r) => {
            let responseFilteredItems = await r.json();
            if (responseFilteredItems.length === 0) {
                setProducts(null);
            } else {
                setProducts(
                    searchQuery === ""
                        ? responseFilteredItems.results
                        : responseFilteredItems
                );
            }
        });
    }

    return content;
}

export default Dashboard;
