import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./assets/css/normalize.css";
import "./assets/css/style.scss";

import "./i18n";

import Home from "./Views/Home";
import Shop from "./Views/Shop";
import ProductDetail from "./Views/ProductDetail";
import Checkout from "./Views/Checkout";
import Order from "./Views/Order";
import Login from "./Views/Login";
import Dashboard from "./Views/Dashboard";
import AddProduct from "./Views/AddProduct";
import UpdateProduct from "./Views/UpdateProduct";
import TOC from "./Views/TOC";

import Nav from "./Components/Nav";
import Footer from "./Components/Footer";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Suspense fallback="loading">
                <Nav />
                <Switch>
                    <Route path="/shop/:id" component={ProductDetail} />
                    <Route path="/shop" component={Shop} />
                    <Route path="/checkout" component={Checkout} />
                    <Route path="/order" component={Order} />
                    <Route path="/login" component={Login} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/addproduct" component={AddProduct} />
                    <Route
                        path="/updateproduct/:id"
                        component={UpdateProduct}
                    />
                    <Route path="/toc" component={TOC} />
                    <Route path="/" component={Home} />
                </Switch>
                <Footer />
            </Suspense>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);
