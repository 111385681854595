import React, { Fragment } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import useFetchRequest from "../Hooks/FetchRequest";

import { calculatePrice, calculatePriceExclVat, calculateVAT, calculateTotal } from "../Functions/Prices";
import { deleteBasket } from "../Components/Basket";

import Product from "../Components/Product";

import BannerImg from "../assets/img/dayachocolates-banner.jpg";


function Order(){
    const location = useLocation();
    const orderid = new URLSearchParams(location.search).get("orderid");
    const order = useFetchRequest("GET", false, `/shop/order/${orderid}`);
    const items = useFetchRequest("GET", false, `/shop/products?limit=2`);
    const { t } = useTranslation(['shop', 'common']);
    let content = <h2>{t("common:loading")}</h2>;

    if (!orderid || order?.error) { return <Redirect to="/shop" />}

    if (order && items){
        content = (
            <main className="order">
                <div>
                    <img src={BannerImg} alt="Dayachocolates" />
                </div>
                <section>
                    {order.status === "paid" ? <Succes /> : <Failed />}
                    <OrderSheet products={order.products} date={order.date_time} shipmentCost={order.shipment_cost} orderid={order.orderid}/>
                    <h3>{t("othersBought")}</h3>

                    <div>
                        {items.results.map((product, index) => {
                            return <Product product={product} key={index} link={`/shop/${product.id}`} />
                        })}
                    </div>
                </section>
            </main>
        )
    }

    return content;
}

function Succes() {
    const { t } = useTranslation(['shop', 'common']);

    return (
        <Fragment>
            {deleteBasket()}
            <h2>{t("thanking.short")}</h2>
            <p>{t("thanking.long")}</p>
        </Fragment>
    );
}

function Failed() {
    const { t } = useTranslation(['shop', 'common']);

    return (
        <Fragment>
            <h2>{t("failed.short")}</h2>
            <p>{t("failed.long")}</p>
        </Fragment>
    );
}

function OrderSheet(props) {
    const { t } = useTranslation(['shop', 'common']);
    let { products, date, shipmentCost, orderid } = props;
    products = products.map((entry) => {
        let tmp = {...entry.product};
        tmp["quantity"] = entry.quantity;
        return tmp;
    })
    let content = (
        <section>
            <h3>{t("order.title")}</h3>
            <p>{t("order.orderDate")}: {new Date(date).toUTCString()}</p>
            <p>{t("order.orderId")}: {orderid}</p>
            
            <table>
                <thead>
                    <tr>
                        <th>{t("order.article")}</th>
                        <th>{t("order.quantity")}</th>
                        <th>{t("order.price")}</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((item, key) => {
                        return <tr key={key} className={key % 2 === 0 ? "white" : "colored"}>
                            <td>{item.title}</td>
                            <td>{item.quantity}</td>
                            <td>€{calculatePrice(item)}</td>
                        </tr>
                    })}
                    <tr className='detail-info'>
                        <td></td>
                        <td>{t("order.subtotal")}: </td>
                        <td>€{calculatePriceExclVat(products)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{t("order.shipment")}: </td>
                        <td>€{shipmentCost.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{t("order.vat")}: </td>
                        <td>€{calculateVAT(products)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{t("order.total")}:</td>
                        <td>€{calculateTotal(products, shipmentCost).toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
  
    return content;
}

export default Order;