import React from "react";
import { useTranslation } from "react-i18next";

import TeleponeIcon from "../assets/img/phone.svg";
import MailIcon from "../assets/img/mail.svg";
import LocationIcon from "../assets/img/location-icon-white.svg";
import BriefcaseIcon from "../assets/img/briefcase.svg";
import ShoppingIcon from "../assets/img/shopping-bag.svg";
import FacebookIcon from "../assets/img/facebook.svg";
import InstagramIcon from "../assets/img/instagram.svg";
import TripadvisorIcon from "../assets/img/tripadvisor-white.svg";

function Footer() {
    const { t } = useTranslation(["footer", "common"]);

    return (
        <footer id="footer">
            <div>
                <h2>Daya Chocolates</h2>
                <div>
                    <div className="info">
                        <h2>{t("contactInfo")}</h2>
                        <ul>
                            <li>
                                <a href="tel:+32475525900">
                                    <img src={TeleponeIcon} alt="Telephone" />{" "}
                                    +32475 52 59 00
                                </a>
                            </li>
                            <li>
                                <a href="mailto:info@dayachocolates.com">
                                    <img src={MailIcon} alt="Mail" />{" "}
                                    info@dayachocolates.com
                                </a>
                            </li>
                            <li>
                                <a href="https://goo.gl/maps/GR9BxBMigdXz5F3S7">
                                    <img src={LocationIcon} alt="Location" />{" "}
                                    Katelijnestraat 27b
                                    <br />
                                    {t("common:city")}
                                </a>
                            </li>
                            <li>
                                <img src={BriefcaseIcon} alt="VAT-number" /> BE
                                0462.476.501
                            </li>
                            <li>
                                <a href="./shop.html">
                                    <img src={ShoppingIcon} alt="Shop" />
                                    {t("visit")}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="socials">
                        <h2>Socials</h2>
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/Daya-Chocolates-179500518766180">
                                    <img src={FacebookIcon} alt="facebook" />{" "}
                                    <p>{t("facebook")}</p>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/daya_chocolates/">
                                    <img src={InstagramIcon} alt="instagram" />{" "}
                                    <p>{t("instagram")}</p>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tripadvisor.be/Restaurant_Review-g188671-d7106312-Reviews-Daya_Chocolates-Bruges_West_Flanders_Province.html?m=19905">
                                    <img
                                        src={TripadvisorIcon}
                                        alt="Tripadvisor"
                                    />{" "}
                                    <p>{t("tripadvisor")}</p>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="opening-hours">
                        <h3>{t("openHours")}</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{t("days.mon")}</td>
                                    <td>10:00 - 18:00</td>
                                </tr>
                                <tr>
                                    <td>{t("days.tue")}</td>
                                    <td>10:00 - 18:00</td>
                                </tr>
                                <tr>
                                    <td>{t("days.wed")}</td>
                                    <td>10:00 - 18:00</td>
                                </tr>
                                <tr>
                                    <td>{t("days.thu")}</td>
                                    <td>10:00 - 18:00</td>
                                </tr>
                                <tr>
                                    <td>{t("days.fri")}</td>
                                    <td>10:00 - 18:00</td>
                                </tr>
                                <tr>
                                    <td>{t("days.sat")}</td>
                                    <td>10:00 - 18:00</td>
                                </tr>
                                <tr>
                                    <td>{t("days.sun")}</td>
                                    <td>10:00 - 18:00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <iframe
                title="Google Maps Daya Chocolates"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2499.8586853726697!2d3.223078615977819!3d51.20325554071569!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c350c4a84dae9b%3A0x349ae04e3b391bdf!2sDaya%20Chocolates!5e0!3m2!1snl!2sbe!4v1601408987705!5m2!1snl!2sbe"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
            ></iframe>
        </footer>
    );
}

export default Footer;
