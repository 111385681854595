import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import useFetchRequest, { url } from "../Hooks/FetchRequest";

import formDataToJSON from "../Functions/FormDataToJSON";

import LoadingOverlay from "../Components/LoadingOverlay";

import CrossImage from "../assets/img/plus.svg";

function UpdateProduct() {
    const { id } = useParams();
    const product = useFetchRequest("GET", true, `/shop/products/${id}/update`);
    const [images, setImages] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [existingImages, setExistingImages] = useState([]);
    const history = useHistory();
    const imageSelector = useRef(null);
    let content = null;

    useEffect(() => {
        if (product) {
            setLoading(false);
            setExistingImages(product.images);
        }
    }, [product]);

    if (product) {
        content = (
            <div className="add-product">
                <LoadingOverlay visible={Loading} />
                <h2>Update hier het product {product.titles[0].title}</h2>
                <form onSubmit={(e) => submitForm(e)}>
                    <label htmlFor="title-en">Artikelnaam</label>

                    <p>Engels</p>
                    <input
                        name="title-en"
                        id="title-en"
                        required
                        autoFocus
                        defaultValue={product.titles[0].title}
                    />

                    <p>Nederlands</p>
                    <input
                        name="title-nl"
                        id="title-nl"
                        required
                        defaultValue={product.titles[1]?.title}
                    />

                    <p>Duits</p>
                    <input
                        name="title-de"
                        id="title-de"
                        required
                        defaultValue={product.titles[2]?.title}
                    />

                    <p>Frans</p>
                    <input
                        name="title-fr"
                        id="title-fr"
                        required
                        defaultValue={product.titles[3]?.title}
                    />

                    <label htmlFor="price">Prijs</label>
                    <input
                        type="number"
                        name="price"
                        step="0.01"
                        min="0.01"
                        required
                        defaultValue={product.price}
                    />

                    <label htmlFor="vat">BTW</label>
                    <input
                        id="vat"
                        name="vat"
                        type="number"
                        step="1"
                        min="0"
                        required
                        className="info"
                        defaultValue={product.vat}
                    />
                    <h6>BTW percentage</h6>

                    <label htmlFor="discount">Korting</label>
                    <input
                        id="discount"
                        name="discount"
                        type="number"
                        step="1"
                        className="info"
                        defaultValue={product.discount}
                    />
                    <h6>Korting in procent. Optioneel.</h6>

                    <label htmlFor="measurement">Maateenheid</label>
                    <input
                        id="measurement"
                        name="measurement"
                        className="info"
                        defaultValue={product.measurement}
                    />
                    <h6>
                        Maateenheid van het product bv.: reep of kg. Optioneel.
                    </h6>

                    <label htmlFor="weight">Gewicht</label>
                    <input
                        id="weight"
                        name="weight"
                        type="number"
                        step="0.01"
                        className="info"
                        defaultValue={product.weight}
                    />
                    <h6>Gewicht in kg.</h6>

                    <label htmlFor="description-en">Beschrijving</label>
                    <p>Engels</p>
                    <textarea
                        name="description-en"
                        id="description-en"
                        required
                        defaultValue={product.descriptions[0].description}
                    ></textarea>

                    <p>Nederlands</p>
                    <textarea
                        name="description-nl"
                        id="description-nl"
                        required
                        defaultValue={product.descriptions[1]?.description}
                    ></textarea>

                    <p>Duits</p>
                    <textarea
                        name="description-de"
                        id="description-de"
                        required
                        defaultValue={product.descriptions[2]?.description}
                    ></textarea>

                    <p>Frans</p>
                    <textarea
                        name="description-fr"
                        id="description-fr"
                        required
                        defaultValue={product.descriptions[3]?.description}
                    ></textarea>

                    <label htmlFor="inStock" className="checkBoxLabel">
                        Is dit product in stock?
                    </label>
                    <input
                        type="checkbox"
                        defaultChecked={product.inStock}
                        name="inStock"
                        id="inStock"
                    />

                    <label htmlFor="category">Categorie</label>
                    <select
                        id="category"
                        name="category"
                        defaultValue={product.category.toLowerCase()}
                    >
                        <option value="">Geen</option>
                        <option value="chocolate tablets">
                            chocolate tablets
                        </option>
                        <option value="Pralines">Pralines</option>
                        <option value="chocolate">chocolate</option>
                        <option value="handmade">handmade</option>
                        <option value="Truffels">Truffels</option>
                        <option value="promotions">promotions</option>
                        <option value="figures">figures</option>
                        <option value="Souvenir">Souvenir</option>
                        <option value="candy">candy</option>
                        <option value="packing">packing</option>
                    </select>

                    <label htmlFor="images">Afbeeldingen</label>
                    <input
                        id="images"
                        ref={imageSelector}
                        onChange={(e) => addImage(e)}
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        multiple
                    />
                    <div className="flex-row image-row">
                        {existingImages.map(({ id, image }, key) => {
                            return (
                                <div key={key}>
                                    <img
                                        src={`${url}${image}`}
                                        alt={image.name}
                                    />
                                    {(existingImages.length > 1 ||
                                        images.length > 0) && (
                                        <img
                                            className="delete-icon"
                                            src={CrossImage}
                                            alt="delete"
                                            onClick={() => deleteImage(id)}
                                        />
                                    )}
                                </div>
                            );
                        })}
                        {images.map((image, key) => {
                            return (
                                <img
                                    key={key}
                                    src={URL.createObjectURL(image)}
                                    alt={image.name}
                                />
                            );
                        })}
                    </div>
                    <button
                        formNoValidate
                        onClick={(e) => {
                            e.preventDefault();
                            imageSelector.current.click();
                        }}
                        htmlFor="image"
                    >
                        Voeg afbeelding toe
                    </button>

                    <input type="submit" value="Update product" />
                </form>
            </div>
        );
    }

    function addImage(e) {
        setImages([...e.target.files]);
    }

    function deleteImage(imageId) {
        const options = {
            method: "DELETE",
            headers: {
                Authorization: localStorage.getItem("Token"),
            },
        };

        fetch(`${url}/shop/products/${id}/image/${imageId}`, options).then(
            (r) => {
                if (r.ok) {
                    setExistingImages(
                        existingImages.filter((image) => image.id !== imageId)
                    );
                }
            }
        );
    }

    function submitForm(e) {
        e.preventDefault();
        const form = e.target;

        let body = formDataToJSON(new FormData(form));
        body["inStock"] = document.querySelector("#inStock").checked;

        const options = {
            method: "PATCH",
            headers: {
                Authorization: localStorage.getItem("Token"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };

        fetch(`${url}/shop/products/${id}/update`, options).then(async (r) => {
            if (r.ok) {
                for (let i = 0; i < imageSelector.current.files.length; i++) {
                    let imageBody = new FormData();
                    imageBody.append("image", imageSelector.current.files[i]);

                    let image_options = {
                        method: "POST",
                        headers: {
                            authorization: `${localStorage.getItem("Token")}`,
                        },
                        body: imageBody,
                    };

                    await fetch(
                        `${url}/shop/products/${id}/image`,
                        image_options
                    );
                }

                history.push("/dashboard");
            }
        });
    }

    return content;
}

export default UpdateProduct;
