import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { url } from "../Hooks/FetchRequest";

function Product({ product, link }) {
    const { i18n } = useTranslation("shop");

    return (
        <Link to={link}>
            <article className="shop-item">
                <img
                    src={url + product.images[0].image}
                    alt={product.titles[0].title}
                />
                <h2>
                    {
                        product.titles.filter(
                            (title) => title.lang === i18n.languages[0]
                        )[0].title
                    }
                </h2>
                <p>
                    {product.descriptions
                        .filter((desc) => desc.lang === i18n.languages[0])[0]
                        .description.substring(0, 50)}
                </p>
                <div>
                    <p>
                        <sup>€</sup>
                        {product?.discount !== 0
                            ? (
                                  product.price *
                                  (1 - product.discount / 100)
                              ).toFixed(2)
                            : product.price.toFixed(2)}

                        {product.measurement !== "" &&
                            ` / ${product.measurement}`}
                    </p>
                    <button>Order</button>
                </div>
            </article>
        </Link>
    );
}

export default Product;
