import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";

import Basket from "./Basket";

import NavIcon from "../assets/img/menu-icon.svg";
import CorssIcon from "../assets/img/plus-circle.svg";
import { t } from "i18next";

function Nav() {
    const [visible, toggleVisible] = useState(false);
    const { t } = useTranslation("nav");

    return (
        <Fragment>
            <SideMenu visible={visible} toggleVisible={toggleVisible} />
            <nav>
                <img
                    src={NavIcon}
                    alt="menu"
                    onClick={() => toggleVisible(!visible)}
                />
                <Link to="/">Daya Chocolates</Link>
                <ul>
                    <li>
                        <HashLink to="/#aboutus">{t("aboutUs")}</HashLink>
                    </li>
                    <li>
                        <Link to="/shop" href="#">
                            {t("shop")}
                        </Link>
                    </li>
                    <li>
                        <HashLink to="/#footer">{t("contact")}</HashLink>
                    </li>
                    <li>
                        <Link to="/checkout">
                            <Basket />
                        </Link>
                    </li>
                </ul>
                <Link to="/checkout" className="basket-sm-nav">
                    <Basket />
                </Link>
                <LanguageSelector />
            </nav>
        </Fragment>
    );
}

function SideMenu(props) {
    const { visible, toggleVisible } = props;

    return (
        <div
            className="sideMenu"
            style={{ display: visible ? "flex" : "none" }}
        >
            <img
                src={CorssIcon}
                alt="exit"
                onClick={() => toggleVisible(!visible)}
            />
            <ul onClick={() => toggleVisible(!visible)}>
                <li>
                    <Link to="/">Daya Chocolates</Link>
                </li>
                <li>
                    <HashLink to="/#aboutus">{t("aboutUs")}</HashLink>
                </li>
                <li>
                    <Link to="/shop">{t("shop")}</Link>
                </li>
                <li>
                    <HashLink to="/#footer">{t("contact")}</HashLink>
                </li>
            </ul>
        </div>
    );
}

export default Nav;
